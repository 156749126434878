import React from "react";
import styled from "styled-components";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Skeleton from "@mui/material/Skeleton";

import theme from "../../styles/theme";
// import { getTeamCar } from "../../utils/helpers/teams";
import { isDark } from "../../utils/helpers/colors";

const TeamName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Position = styled.div`
  width: 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const Arrow = styled(ChevronRightIcon)`
  @media (max-width: 768px) {
    display: none !important;
  }
`;

const getOppositeColor = (color, defaultColor) => {
  if (!color) color = defaultColor;
  return isDark(color) ? theme.colors.white : theme.colors.darkLight;
};

const DriverNames = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #67676d;
  white-space: pre-wrap;
`;

const StandingItemContainer = styled.div`
  margin: auto;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  background-color: ${theme.colors.darkLight};
  border-radius: 4px;
  padding: 8px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  user-select: none;
  transition: all 0.3s ease-in-out;

  background: linear-gradient(
      to left,
      ${theme.colors.darkLight} 50%,
      ${(props) => props.color || theme.colors.white} 50%
    )
    right;
  background-size: 200% 100%;

  &:hover {
    background-position: left;

    & ${TeamName} {
      color: ${(props) => getOppositeColor(props.color, theme.colors.white)};
      transition: all 0.3s ease-in-out;
    }
    & ${DriverNames} {
      color: ${(props) => getOppositeColor(props.color, theme.colors.white)};
      transition: all 0.3s ease-in-out;
    }

    & ${Position} {
      color: ${(props) => getOppositeColor(props.color, theme.colors.white)};
      transition: all 0.3s ease-in-out;
    }

    & ${Arrow} {
      color: ${(props) => getOppositeColor(props.color, theme.colors.white)};
      transition: all 0.3s ease-in-out;
    }
  }
  @media (max-width: ${theme.breakpointsCustom.md}px) {
    max-width: 500px;
  }
`;

const Side = styled.div`
  // height: 40px;
  border-radius: 4px;
  margin: 2px 8px;
  //padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const ColorBlock = styled.div`
  width: 4px;
  height: 16px;
  background-color: ${(props) => props.color};

  @media (max-width: 480px) {
    height: 56px;
  }
`;

const CarContainer = styled.div`
  height: 40px;
  width: auto;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Car = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const Points = styled.div`
  color: ${theme.colors.white};
  background-color: ${theme.colors.dark};
  padding: 4px 16px;
  border-radius: 32px;
  font-weight: 600;
  text-wrap: nowrap;
  width: 130px;
  text-align: center;
`;

const TeamDrivers = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  justify-content: flex-start;
  align-items: flex-end;

  @media (max-width: 480px) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    column-gap: 2px;
  }
`;

const StandingItemContainerSkeleton = styled.div`
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  background-color: ${theme.colors.darkLight};
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;

  @media (max-width: ${theme.breakpointsCustom.md}px) {
    max-width: 500px;
  }
`;

export const ConstructorStandingsItem = ({ team, position, points }) => {
  const { color, name, livery, drivers } = team;
  const isMobile = window.innerWidth < 480;

  return (
    <StandingItemContainer color={color}>
      <Side>
        <Position>{position}</Position>
        <ColorBlock color={color} />
        <TeamDrivers>
          <TeamName> {name}</TeamName>
          <DriverNames>
            {drivers
              .map((driver) => `${driver.firstName} ${driver.lastName}`)
              .join(isMobile ? "\n" : " / ")}
          </DriverNames>
        </TeamDrivers>
      </Side>

      <Side>
        <CarContainer>
          {livery?.image && <Car src={livery.image} />}
        </CarContainer>
        <Points>{`${points} ОЧ.`}</Points>
        {/* <Arrow sx={{ color: theme.colors.white }} /> */}
      </Side>
    </StandingItemContainer>
  );
};

export const ConstructorStandingsItemSkeleton = () => {
  return (
    <StandingItemContainerSkeleton>
      <Skeleton
        variant="rectangular"
        height="100%"
        width="15%"
        style={{ borderRadius: "12px" }}
      />
      <Skeleton
        variant="rectangular"
        height="100%"
        width="10%"
        style={{ borderRadius: "12px" }}
      />
    </StandingItemContainerSkeleton>
  );
};
