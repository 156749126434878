import Season2 from "../assets/ArchiveSlider/season2.png";
import Season3 from "../assets/ArchiveSlider/season3.png";
import Season4 from "../assets/ArchiveSlider/season4.png";
import Season5 from "../assets/ArchiveSlider/season5.png";
import Season6 from "../assets/ArchiveSlider/season6.png";
import Season7 from "../assets/ArchiveSlider/season7.png";
import Season8 from "../assets/ArchiveSlider/season8.png";

export const archiveItems = [
  {
    image: Season8,
    link: "/videos/seasons/8",
  },
  {
    image: Season7,
    link: "/videos/seasons/7",
  },
  {
    image: Season6,
    link: "/videos/seasons/6",
  },
  {
    image: Season5,
    link: "/videos/seasons/5",
  },
  {
    image: Season4,
    link: "/videos/seasons/4",
  },
  {
    image: Season3,
    link: "/videos/seasons/3",
  },
  {
    image: Season2,
    link: "/videos/seasons/2",
  },
];
