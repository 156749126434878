import React, { useState, useMemo } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import styled from "styled-components";
import _ from "lodash";

import theme from "../../styles/theme";
import config from "../../config";

import PodiumBackground from "../Backgrounds//PodiumBackground";
import { default as lastRaceImage } from "../../assets/last-race.jpg";

import {
  LastRaceResulstTab,
  LastRaceResulstTabSceleton,
} from "./LastRaceResults";

import "swiper/css";
import "swiper/css/navigation";

import BackgroundPodiumDriver from "./BackgroundPodiumDriverSlider";
import BackgroundPodiumTeam from "./BackgroundPodiumTeamSlider";

import { useScheduleContext } from "../../contexts/schedule";

const StyledTab = styled(Tab)`
  color: ${theme.colors.white} !important;
  font-family: "Roboto Condensed" !important;

  &.Mui-selected {
    color: ${theme.colors.green} !important;
  }
  &.Mui-disabled {
    color: ${theme.colors.grey} !important;
  }
`;

const SeasonStandingsContainer = styled.div`
  max-width: 1100px;
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledTabList = styled(TabList)`
  width: 100vw;
  margin-left: calc(50% - 50vw);
  background-color: ${theme.colors.darkLight};
`;

const StyledTabPanel = styled(TabPanel)`
  padding: 0px !important;
`;

const Standings = () => {
  const [value, setValue] = useState(0);
  const lastSeason = config.constants.lastSeason;
  const { seasons } = useScheduleContext();

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const [currentSeasonId, setCurrentSeasonId] = useState(8);

  const season = useMemo(() => {
    return seasons[lastSeason];
  }, [seasons, lastSeason]);

  if (!season) return <></>;

  const lastFinishedRaceIndex = _.findLastIndex(
    season?.races,
    (race) => race.hasResults
  );

  const lastFinishedRace = season.races[lastFinishedRaceIndex];

  return (
    <SeasonStandingsContainer>
      <TabContext value={value}>
        <StyledTabList
          onChange={handleChange}
          indicatorColor="secondary"
          centered
        >
          <StyledTab label="Пілоти" />
          <StyledTab label="Команди" />
          <StyledTab
            label="Останній Етап"
            //disabled={currentSeasonId !== lastSeason}
            //disabled={true}
          />
        </StyledTabList>

        <StyledTabPanel value={0}>
          <BackgroundPodiumDriver
            currentSeasonId={currentSeasonId}
            setCurrentSeasonId={setCurrentSeasonId}
          />
        </StyledTabPanel>

        <StyledTabPanel value={1}>
          <BackgroundPodiumTeam
            currentSeasonId={currentSeasonId}
            setCurrentSeasonId={setCurrentSeasonId}
          />
        </StyledTabPanel>

        <StyledTabPanel value={2}>
          <PodiumBackground image={lastRaceImage} />
          {!lastFinishedRace && <LastRaceResulstTabSceleton />}
          {lastFinishedRace && (
            <LastRaceResulstTab
              raceId={lastFinishedRace.id}
              stage={lastFinishedRaceIndex + 1}
            />
          )}
        </StyledTabPanel>
      </TabContext>
    </SeasonStandingsContainer>
  );
};

export default Standings;
