import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import theme from "../../styles/theme";

import { TitleContainer } from "./LastRaceResults";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useStandingsContext } from "../../contexts/standings";

import ConstructorStandings from "../ConstructorStandings";
import ConstructorsPodium from "./PodiumConstructors";
import { useScheduleContext } from "../../contexts/schedule";
import config from "../../config";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { TitleTopContainer } from "./LastRaceResults";

import teamChampionSeason1ImagePhone from "../../assets/PodiumBackground/Teams/TeamChampionSeason1Phone.png";
import teamChampionSeason2ImagePhone from "../../assets/PodiumBackground/Teams/TeamChampionSeason2Phone.png";
import teamChampionSeason3ImagePhone from "../../assets/PodiumBackground/Teams/TeamChampionSeason3Phone.png";
import teamChampionSeason4ImagePhone from "../../assets/PodiumBackground/Teams/TeamChampionSeason4Phone.png";
import teamChampionSeason5ImagePhone from "../../assets/PodiumBackground/Teams/TeamChampionSeason5Phone.png";
import teamChampionSeason6ImagePhone from "../../assets/PodiumBackground/Teams/TeamChampionSeason6Phone.png";
import teamChampionSeason7ImagePhone from "../../assets/PodiumBackground/Teams/TeamChampionSeason7Phone.png";
import teamChampionSeasonNonePhone from "../../assets/PodiumBackground/Teams/TeamChampionSeasonNonePhone.png";

import teamChampionSeason7Image from "../../assets/PodiumBackground/Teams/TeamChampionSeason7.png";
import teamChampionSeason6Image from "../../assets/PodiumBackground/Teams/TeamChampionSeason6.png";
import teamChampionSeason5Image from "../../assets/PodiumBackground/Teams/TeamChampionSeason5.png";
import teamChampionSeason4Image from "../../assets/PodiumBackground/Teams/TeamChampionSeason4.png";
import teamChampionSeason3Image from "../../assets/PodiumBackground/Teams/TeamChampionSeason3.png";
import teamChampionSeason2Image from "../../assets/PodiumBackground/Teams/TeamChampionSeason2.png";
import teamChampionSeason1Image from "../../assets/PodiumBackground/Teams/TeamChampionSeason1.png";
import teamChampionSeasonNone from "../../assets/PodiumBackground/Teams/TeamChampionSeasonNone.png";

const ChangeSeasonBtnContainer = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const StyledIconPrev = styled.i`
  &.swiper-button-disabled {
    background-color: transparent;
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }
`;

const StyledIconNext = styled.i`
  &.swiper-button-disabled {
    background-color: transparent;
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  left: 0px;
  height: 300px;
  background: linear-gradient(
    90deg,
    ${theme.colors.darkLight} 0%,
    ${theme.colors.darkLight} 45%,
    ${theme.colors.darkLight} 55%,
    ${theme.colors.darkLight} 100%
  );
`;

const StyledImg = styled.img`
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  left: 0px;
  height: 300px;
  object-fit: cover;
  opacity: 0.5;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  height: 300px;
`;

const BackgroundPodiumTeam = ({ currentSeasonId, setCurrentSeasonId }) => {
  const { loadSeason: loadSeasonStandings, standings } = useStandingsContext();
  const { seasons, loadSchedule } = useScheduleContext({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getImage = (season) => {
    switch (season) {
      case 1:
        return windowWidth < 768
          ? teamChampionSeason1ImagePhone
          : teamChampionSeason1Image;
      case 2:
        return windowWidth < 768
          ? teamChampionSeason2ImagePhone
          : teamChampionSeason2Image;
      case 3:
        return windowWidth < 768
          ? teamChampionSeason3ImagePhone
          : teamChampionSeason3Image;
      case 4:
        return windowWidth < 768
          ? teamChampionSeason4ImagePhone
          : teamChampionSeason4Image;
      case 5:
        return windowWidth < 768
          ? teamChampionSeason5ImagePhone
          : teamChampionSeason5Image;
      case 6:
        return windowWidth < 768
          ? teamChampionSeason6ImagePhone
          : teamChampionSeason6Image;
      case 7:
        return windowWidth < 768
          ? teamChampionSeason7ImagePhone
          : teamChampionSeason7Image;
      case 8:
        return windowWidth < 768
          ? teamChampionSeasonNonePhone
          : teamChampionSeasonNone;
      default:
        return windowWidth < 768
          ? teamChampionSeasonNonePhone
          : teamChampionSeasonNone;
    }
  };

  const handleSeasonChangeLeft = () => {
    const nextSeason =
      currentSeasonId === 1 ? config.constants.lastSeason : currentSeasonId - 1;
    setCurrentSeasonId(nextSeason);
  };

  const handleSeasonChangeRight = () => {
    const nextSeason =
      currentSeasonId === config.constants.lastSeason ? 1 : currentSeasonId + 1;
    setCurrentSeasonId(nextSeason);
  };

  useEffect(() => {
    loadSchedule(currentSeasonId);
    loadSeasonStandings(currentSeasonId);
    currentSeasonId - 1 > 0 && loadSchedule(currentSeasonId - 1);
    currentSeasonId - 1 > 0 && loadSeasonStandings(currentSeasonId - 1);
  }, [currentSeasonId]); // eslint-disable-line

  const renderPodium = currentSeasonId !== 8; //HARDCODE

  return (
    <>
      <ConstructorsPodium
        teams={standings[currentSeasonId]?.teams}
        renderPodium={renderPodium}
      >
        <TitleTopContainer>Командний залік</TitleTopContainer>
        <Fragment>
          <Background>
            <Swiper
              modules={[Navigation, Pagination]}
              style={{ width: "100%" }}
              watchSlidesProgress={false}
              slidesPerView={"auto"}
              spaceBetween={0}
              className="mySwiper"
              speed={500}
              navigation={{
                nextEl: ".review-swiper-button-next-podium-drivers",
                prevEl: ".review-swiper-button-prev-podium-drivers",
              }}
              initialSlide={currentSeasonId - 1}
              noSwiping={true}
              noSwipingSelector=".swiper-slide"
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map((season) => (
                <StyledSwiperSlide key={season}>
                  <StyledImg src={getImage(season)} />
                </StyledSwiperSlide>
              ))}
            </Swiper>
          </Background>
        </Fragment>
        <ChangeSeasonBtnContainer>
          <StyledIconPrev
            onClick={handleSeasonChangeLeft}
            className="review-swiper-button-prev-podium-drivers"
          >
            <IconButton>
              <KeyboardArrowLeftIcon
                sx={{ fontSize: "38px", color: `${theme.colors.green}` }}
              />
            </IconButton>
          </StyledIconPrev>
          <TitleContainer>{seasons[currentSeasonId]?.shortName}</TitleContainer>
          <StyledIconNext
            onClick={handleSeasonChangeRight}
            className="review-swiper-button-next-podium-drivers"
          >
            <IconButton>
              <KeyboardArrowRightIcon
                sx={{ fontSize: "38px", color: `${theme.colors.green}` }}
              />
            </IconButton>
          </StyledIconNext>
        </ChangeSeasonBtnContainer>
      </ConstructorsPodium>
      <div style={{ marginTop: 12 }}>
        <ConstructorStandings teams={standings[currentSeasonId]?.teams} />
      </div>
    </>
  );
};

export default BackgroundPodiumTeam;
