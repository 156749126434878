import React, { createContext, useCallback, useContext, useState } from "react";

import { axiosInstance } from "../utils/axios";

const DriversContext = createContext({
  drivers: [],
});

export const useDriversContext = () => {
  return useContext(DriversContext);
};

export const DriversProvider = ({ children }) => {
  const [drivers, setDrivers] = useState([]);

  const fetchDrivers = async (simulator = "") => {
    const url = `/drivers?simulator=${simulator}`;
    const { data } = await axiosInstance.get(url, {
      params: { size: 200 },
    });

    const drivers = data?._embedded?.drivers || [];
    const activeDrivers = drivers?.filter((d) => d.grandPrixCount > 0);

    return { drivers: activeDrivers };
  };

  const loadDrivers = useCallback(async (simulator = "") => {
    const { drivers: loadedDrivers } = await fetchDrivers(simulator);
    setDrivers(loadedDrivers);
  }, []); // eslint-disable-line

  return (
    <DriversContext.Provider value={{ drivers, loadDrivers }}>
      {children}
    </DriversContext.Provider>
  );
};
