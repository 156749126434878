import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import DriverStandings from "../DriverStandings";
import theme from "../../styles/theme";

import { TitleContainer } from "./LastRaceResults";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useStandingsContext } from "../../contexts/standings";
import config from "../../config";

import PodiumDrivers from "./PodiumDrivers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { TitleTopContainer } from "./LastRaceResults";
import { useScheduleContext } from "../../contexts/schedule";

import ChampionSeason1ImagePhone from "../../assets/PodiumBackground/Drivers/championSeason1Phone.png";
import ChampionSeason2ImagePhone from "../../assets/PodiumBackground/Drivers/championSeason2Phone.png";
import ChampionSeason3ImagePhone from "../../assets/PodiumBackground/Drivers/championSeason3Phone.png";
import ChampionSeason4ImagePhone from "../../assets/PodiumBackground/Drivers/championSeason4Phone.png";
import ChampionSeason5ImagePhone from "../../assets/PodiumBackground/Drivers/championSeason5Phone.png";
import ChampionSeason6ImagePhone from "../../assets/PodiumBackground/Drivers/championSeason6Phone.png";
import ChampionSeason7ImagePhone from "../../assets/PodiumBackground/Drivers/championSeason7Phone.png";
import ChampionSeasonNonePhone from "../../assets/PodiumBackground/Drivers/championSeasonNonePhone.png";

import ChampionSeason1Image from "../../assets/PodiumBackground/Drivers/championSeason1.png";
import ChampionSeason2Image from "../../assets/PodiumBackground/Drivers/championSeason2.png";
import ChampionSeason3Image from "../../assets/PodiumBackground/Drivers/championSeason3.png";
import ChampionSeason4Image from "../../assets/PodiumBackground/Drivers/championSeason4.png";
import ChampionSeason5Image from "../../assets/PodiumBackground/Drivers/championSeason5.png";
import ChampionSeason6Image from "../../assets/PodiumBackground/Drivers/championSeason6.png";
import ChampionSeason7Image from "../../assets/PodiumBackground/Drivers/championSeason7.png";
import ChampionSeasonNone from "../../assets/PodiumBackground/Drivers/championSeasonNone.png";

const ChangeSeasonBtnContainer = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const StyledIconPrev = styled.i`
  &.swiper-button-disabled {
    background-color: transparent;
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }
`;

const StyledIconNext = styled.i`
  &.swiper-button-disabled {
    background-color: transparent;
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }
`;
const Background = styled.div`
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  left: 0px;
  height: 300px;
  background: linear-gradient(
    90deg,
    ${theme.colors.darkLight} 0%,
    ${theme.colors.darkLight} 45%,
    ${theme.colors.darkLight} 55%,
    ${theme.colors.darkLight} 100%
  );
`;

const StyledImg = styled.img`
  position: absolute;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  left: 0px;
  height: 300px;
  object-fit: cover;
  opacity: 0.5;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  height: 300px;
`;

const BackgroundPodiumDriver = ({ currentSeasonId, setCurrentSeasonId }) => {
  const { loadSeason: loadSeasonStandings, standings } = useStandingsContext();
  const { seasons, loadSchedule } = useScheduleContext({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getImage = (season) => {
    switch (season) {
      case 1:
        return windowWidth < 768
          ? ChampionSeason1ImagePhone
          : ChampionSeason1Image;
      case 2:
        return windowWidth < 768
          ? ChampionSeason2ImagePhone
          : ChampionSeason2Image;
      case 3:
        return windowWidth < 768
          ? ChampionSeason3ImagePhone
          : ChampionSeason3Image;
      case 4:
        return windowWidth < 768
          ? ChampionSeason4ImagePhone
          : ChampionSeason4Image;
      case 5:
        return windowWidth < 768
          ? ChampionSeason5ImagePhone
          : ChampionSeason5Image;
      case 6:
        return windowWidth < 768
          ? ChampionSeason6ImagePhone
          : ChampionSeason6Image;
      case 7:
        return windowWidth < 768
          ? ChampionSeason7ImagePhone
          : ChampionSeason7Image;
      case 8:
        return windowWidth < 768 ? ChampionSeasonNonePhone : ChampionSeasonNone;
      default:
        return windowWidth < 768 ? ChampionSeasonNonePhone : ChampionSeasonNone;
    }
  };

  const handleSeasonChangeLeft = () => {
    const nextSeason =
      currentSeasonId === 1 ? config.constants.lastSeason : currentSeasonId - 1;
    setCurrentSeasonId(nextSeason);
  };

  const handleSeasonChangeRight = () => {
    const nextSeason =
      currentSeasonId === config.constants.lastSeason ? 1 : currentSeasonId + 1;
    setCurrentSeasonId(nextSeason);
  };

  useEffect(() => {
    loadSchedule(currentSeasonId);
    loadSeasonStandings(currentSeasonId);
    currentSeasonId - 1 > 0 && loadSchedule(currentSeasonId - 1);
    currentSeasonId - 1 > 0 && loadSeasonStandings(currentSeasonId - 1);
  }, [currentSeasonId]); // eslint-disable-line

  return (
    <>
      <PodiumDrivers drivers={standings[currentSeasonId]?.drivers}>
        <TitleTopContainer>Особистий залік</TitleTopContainer>
        <Fragment>
          <Background>
            <Swiper
              modules={[Navigation, Pagination]}
              style={{ width: "100%" }}
              watchSlidesProgress={false}
              slidesPerView={"auto"}
              spaceBetween={0}
              className="mySwiper"
              speed={500}
              navigation={{
                nextEl: ".review-swiper-button-next-podium-drivers",
                prevEl: ".review-swiper-button-prev-podium-drivers",
              }}
              initialSlide={currentSeasonId - 1}
              noSwiping={true}
              noSwipingSelector=".swiper-slide"
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map((season) => (
                <StyledSwiperSlide>
                  <StyledImg src={getImage(season)} />
                </StyledSwiperSlide>
              ))}
            </Swiper>
          </Background>
        </Fragment>
        <ChangeSeasonBtnContainer>
          <StyledIconPrev
            onClick={handleSeasonChangeLeft}
            className="review-swiper-button-prev-podium-drivers"
          >
            <IconButton>
              <KeyboardArrowLeftIcon
                sx={{ fontSize: "38px", color: `${theme.colors.green}` }}
              />
            </IconButton>
          </StyledIconPrev>
          <TitleContainer>{seasons[currentSeasonId]?.shortName}</TitleContainer>
          <StyledIconNext
            onClick={handleSeasonChangeRight}
            className="review-swiper-button-next-podium-drivers"
          >
            <IconButton>
              <KeyboardArrowRightIcon
                sx={{ fontSize: "38px", color: `${theme.colors.green}` }}
              />
            </IconButton>
          </StyledIconNext>
        </ChangeSeasonBtnContainer>
      </PodiumDrivers>
      <div style={{ marginTop: 12 }}>
        <DriverStandings drivers={standings[currentSeasonId]?.drivers} />
      </div>
    </>
  );
};

export default BackgroundPodiumDriver;
