import React from "react";
import styled from "styled-components";

import { Driver, DriverSkeleton } from "../Driver/DriverCard";
import { useDriversContext } from "../../contexts/drivers";
import theme from "../../styles/theme";
import _ from "lodash";

const DriversContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  margin-left: 0;
  margin-right: 0;
  transition: all 0.3s linear;

  @media (max-width: ${theme.breakpointsCustom.lg}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${theme.breakpointsCustom.sm}px) {
    grid-template-columns: 1fr;
  }
`;

const Drivers = () => {
  const { drivers } = useDriversContext();

  return (
    <DriversContainer>
      {!drivers.length &&
        _.times(17, (index) => <DriverSkeleton key={index} />)}
      {drivers.length > 0 &&
        drivers.map((driver) => (
          <Driver {...driver} /> //HARDCODE
        ))}
    </DriversContainer>
  );
};

export default Drivers;
