import React, { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import styled from "styled-components";

import theme from "../../styles/theme";
import { RaceResults } from "../RaceResults";
import Link from "../Common/Link";
import Skeleton from "@mui/material/Skeleton";
import { RaceResultsRowSkeleton } from "../../components/RaceResults/RaceResultsRow";
import { useRacesContext } from "../../contexts/races";

import _ from "lodash";

const PodiumBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleTopContainer = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  user-select: none;
`;

export const TitleContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  user-select: none;
  width: 280px;
  margin: auto;
`;

const StyledTab = styled(Tab)`
  color: ${theme.colors.white} !important;
  font-family: "Roboto Condensed" !important;

  &.Mui-selected {
    color: ${theme.colors.green} !important;
  }
`;

const LastRaceTabsContainer = styled.div`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledTabList = styled(TabList)`
  width: 100%;
  position: relative;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: ${theme.colors.dark}90 !important;
`;

const StyledTabPanel = styled(TabPanel)`
  padding: 0px !important;
`;

const StyledButton = styled(Button)`
  margin: auto !important;
  font-family: "Roboto Condensed" !important;
  font-weight: bold !important;
  width: 200px;

  &:hover {
    background-color: ${theme.colors.white}20 !important;
  }
`;

export const LastRaceResulstTab = ({ raceId, stage }) => {
  const { races, loadRace } = useRacesContext();
  const [value, setValue] = useState(1);

  const [race, setRace] = useState(null);
  useEffect(() => {
    loadRace(raceId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const raceValue = races?.[raceId];
    if (!raceValue) return;
    setRace(raceValue);
    setValue(raceValue.sprintResults ? 2 : 1);
    // eslint-disable-next-line
  }, [races]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const isSprintResults = race?.sprintResults;

  if (!race) return null;

  return (
    <div>
      <PodiumBlock>
        <TitleTopContainer>{race.details.shortName}</TitleTopContainer>
        <TitleContainer>Етап {stage}</TitleContainer>
      </PodiumBlock>

      <LastRaceTabsContainer>
        <TabContext value={value}>
          <StyledTabList
            onChange={handleChange}
            indicatorColor="secondary"
            centered
          >
            <StyledTab label="Кваліфікація" />
            {isSprintResults && <StyledTab label="Спринт" />}

            <StyledTab label="Гонка" />
          </StyledTabList>

          <StyledTabPanel value={0}>
            <RaceResults type="Qualification" race={race} />
          </StyledTabPanel>

          <StyledTabPanel value={isSprintResults ? 1 : ""}>
            <RaceResults type="Sprint" race={race} />
          </StyledTabPanel>

          <StyledTabPanel value={isSprintResults ? 2 : 1}>
            <RaceResults type="Race" race={race} />
          </StyledTabPanel>
          <Link to="/results">
            <div style={{ display: "flex", marginTop: 8 }}>
              <StyledButton
                size="small"
                sx={{
                  color: theme.colors.white,
                }}
              >
                Переглянути все
              </StyledButton>
            </div>
          </Link>
        </TabContext>
      </LastRaceTabsContainer>
    </div>
  );
};

export const LastRaceResulstTabSceleton = () => {
  return (
    <div>
      <PodiumBlock>
        <TitleTopContainer>
          {" "}
          <Skeleton
            height="100%"
            width="15%"
            style={{ borderRadius: "12px" }}
          />
        </TitleTopContainer>
        <TitleContainer>
          <Skeleton
            height="100%"
            width="10%"
            style={{ borderRadius: "12px" }}
          />
        </TitleContainer>
      </PodiumBlock>

      <LastRaceTabsContainer>
        <Skeleton
          height="48px"
          width="100%"
          style={{ borderRadius: "0px" }}
        ></Skeleton>

        {_.times(17, (index) => (
          <RaceResultsRowSkeleton key={index} />
        ))}
      </LastRaceTabsContainer>
    </div>
  );
};
