const data = {
  seasonHighlights: {
    2: "PLOIm3OyfdcPrj4PDaTAmGcAyolVfwR-Q5",
    3: "PLOIm3OyfdcPo-cpTg1MFyPQwzXLelmvJ3",
    4: "PLOIm3OyfdcPr3jmBrAorO3fAimvmAyZyD",
    5: "PLOIm3OyfdcPqFmW0ixKpyfFIIRaOCYYXc",
    6: "PLOIm3OyfdcPoZz5GtKz2Dx1lL7tDOMktu",
    7: "PLOIm3OyfdcPo9Wat2MMIi3o8HyLahw4Yw",
    8: "PLOIm3OyfdcPo501hSAX-bWbJjiqsddcx0",
  },
};

export default data;

export const getHighlightsPlaylistId = (seasonid) => {
  return data.seasonHighlights[seasonid];
};
